<template>
    <div class="map_layout">
        <l-map
        style="height: 100%; width: 100%;border-radius: 2px;text-align:left;"
        :zoom="zoom"
        :center="center"
        @update:zoom="zoomUpdate"
        name="map"
        id="map"
        ref="map"
        @ready="onReady"
        @locationfound="onLocationFound"
        >
            <l-control-layers position="topright"  :collapsed="true" ref="layersControl" ></l-control-layers>
            
            <!-- <l-tile-layer :url="url"></l-tile-layer> -->
            <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"/>

            <l-marker v-for="marker, index in stations_check" :lat-lng="[marker.lat,marker.long]" :key="index" @click="markerClick(marker)">
                <l-icon
                :icon-size="dynamicSize"
                :icon-anchor="dynamicAnchor"
                :popupAnchor="dynamicPopupAnchor"
                class-name="someExtraClass"
                >
                    <!-- <img :src="icon.Url" :height="dynamicSize[1]"> -->
                    <img src="@/assets/markers/pin_g.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id <= 0">
                    <img src="@/assets/markers/pin_0.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 1">
                    <img src="@/assets/markers/pin_1.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 2">
                    <img src="@/assets/markers/pin_2.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 3">
                    <img src="@/assets/markers/pin_3.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 4">
                    <img src="@/assets/markers/pin_4.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 5">
                    <img src="@/assets/markers/pin_5.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 6">
                    <img src="@/assets/markers/pin_6.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 7">
                    <img src="@/assets/markers/pin_7.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 8">
                    <img src="@/assets/markers/pin_8.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 9">
                    <img src="@/assets/markers/pin_9.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 10">
                    <strong class="centered" :style="dynamicFont" style="padding-top:2px;color:black;">
                        {{ aqlVal(marker.AQILast[mapshowSelected],mapshowSelected) }}
                    </strong>
                </l-icon>
                <!-- <l-popup>{{ $i18n.locale === 'th' ? marker.nameTH : marker.nameEN }}</l-popup> -->
            </l-marker>

            <l-control position="topright" style="text-align:left;">
                <b-form-select v-model="mapshowSelected" :options="mapshowOptions" style="text-align:left;"></b-form-select>
            </l-control>
            <l-control position="topleft" >
                <b-button size="sm" class="location" @click="clickGetLocation()">
                    <i class="fas fa-street-view"></i>
                </b-button>
            </l-control>
            <b-card class="card_popup" v-bind:class="{open_popup:popupOpen}" >
                <div size="" class="close_bt" @click="closePopup()">
                    <i class="fas fa-times" style="font-size:12px;margin-top:5px;"></i>
                </div>
                <div class="detail" v-if="popupData != null">
                    <div @click="gotoDetail(popupData['stationID'])" v-if="window_width <= 550">
                        <div style="padding-bottom:40px;">
                            <div class="aqiFace">
                                <img src="@/assets/face/icon-nodata.png" height="100" v-if="popupData.AQILast.AQI.color_id <= 0"/>
                                <img src="@/assets/face/icon-excellent.png" height="100" v-if="popupData.AQILast.AQI.color_id == '1'"/>
                                <img src="@/assets/face/icon-satisfactory.png" height="100" v-if="popupData.AQILast.AQI.color_id == '2'"/>
                                <img src="@/assets/face/icon-moderate.png" height="100" v-if="popupData.AQILast.AQI.color_id == '3'"/>
                                <img src="@/assets/face/icon-unhealthy.png" height="100" v-if="popupData.AQILast.AQI.color_id == '4'"/>
                                <img src="@/assets/face/icon-very unhealthy.png" height="100" v-if="popupData.AQILast.AQI.color_id == '5'"/>

                                <p class="aqiFace-Text">
                                    <span className="ver-space">
                                        <strong style="fontSize:24px">
                                            {{popupData.AQILast.AQI.aqi}}
                                        </strong>
                                    </span><br>
                                    <small style="fontSize:14px;">AQI</small>
                                </p>

                                <div style="text-align: center;" class="status_text">
                                    <strong style="fontSize:14px;padding:2px;line-height: 0;" v-if="$i18n.locale === 'th'">
                                        {{type_text[popupData.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                    <strong style="fontSize:14px;padding:2px;line-height: 0;" v-else>
                                        {{type_text_e[popupData.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-row @click="gotoDetail(popupData['stationID'])" v-else>
                        <b-col md="2" style="padding-bottom:40px;">
                            <div class="aqiFace">
                                <img src="@/assets/face/icon-nodata.png" height="100" v-if="popupData.AQILast.AQI.color_id <= 0"/>
                                <img src="@/assets/face/icon-excellent.png" height="100" v-if="popupData.AQILast.AQI.color_id == '1'"/>
                                <img src="@/assets/face/icon-satisfactory.png" height="100" v-if="popupData.AQILast.AQI.color_id == '2'"/>
                                <img src="@/assets/face/icon-moderate.png" height="100" v-if="popupData.AQILast.AQI.color_id == '3'"/>
                                <img src="@/assets/face/icon-unhealthy.png" height="100" v-if="popupData.AQILast.AQI.color_id == '4'"/>
                                <img src="@/assets/face/icon-very unhealthy.png" height="100" v-if="popupData.AQILast.AQI.color_id == '5'"/>

                                <p class="aqiFace-Text">
                                    <span className="ver-space">
                                        <strong style="fontSize:24px">
                                            {{popupData.AQILast.AQI.aqi}}
                                        </strong>
                                    </span><br>
                                    <small style="fontSize:14px;">AQI</small>
                                </p>
                                <!-- <div>
                                    <strong style="fontSize:14px;padding:2px;" v-if="$i18n.locale === 'th'">
                                        {{type_text[popupData.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                    <strong style="fontSize:14px;padding:2px;" v-else>
                                        {{type_text_e[popupData.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                </div> -->
                                <div style="text-align: center;" class="status_text">
                                    <strong style="fontSize:14px;padding:2px;line-height: 0;" v-if="$i18n.locale === 'th'">
                                        {{type_text[popupData.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                    <strong style="fontSize:14px;padding:2px;line-height: 0;" v-else>
                                        {{type_text_e[popupData.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                </div>
                            </div>
                            
                        </b-col>
                        <b-col md="4" >
                            <strong style="fontSize: 16px;">{{ popupData['stationID'] + " " + ($i18n.locale === 'th' ? popupData['areaTH'] : popupData['areaEN']) }}</strong><br>
                            <small style="fontSize: 12px;"><i class="far fa-clock"></i> {{ convertDate(popupData['AQILast']['date'] + " " + popupData['AQILast']['time']) }}</small>
                        </b-col>
                        <b-col md="6">
                            <b-row style="text-align: center;">
                                <b-col v-for="param, index in checkParamsDraw(popupData)" :key="index">
                                    <div v-if="param != ''">
                                        <div v-html="titleParam[param]"></div>
                                        <strong style="fontSize:30px"  :style="{color : indexColor(popupData.AQILast[param].color_id)}">
                                            {{popupData.AQILast[param].aqi === "-1" ? "-" : popupData.AQILast[param].aqi === "-999" ? "-" : param === "AQI" ? popupData.AQILast[param].aqi : popupData.AQILast[param].value}}
                                        </strong>
                                        <div v-html="unit[param]"></div>
                                        <div style="margin-top:-5px;"><small style="color:gray;">{{params_sub[param]}}</small></div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <!-- <b-col md="2" style="text-align: center;" class="mb-3">
                            <div style="borderRadius:15px;padding:5px;" 
                            :style="{background : popupData.AQILast.AQI.color_id < 0 ? null : indexColor(popupData.AQILast.AQI.color_id)}"
                            >
                                <strong style="fontSize:18px;padding:5px;" v-if="$i18n.locale === 'th'">
                                    {{type_text[popupData.AQILast.AQI.color_id - 1]}}
                                </strong>
                                <strong style="fontSize:18px;padding:5px;" v-else>
                                    {{type_text_e[popupData.AQILast.AQI.color_id - 1]}}
                                </strong>
                            </div>
                        </b-col> -->
                    </b-row>
                </div>
                <div v-else>
                    ไม่มีข้อมูล
                </div>
            </b-card>
        </l-map>
    </div>
</template>

<script>
// import L from 'leaflet';
import { LMap ,LTileLayer ,LMarker  ,LControlLayers ,LIcon, LControl} from 'vue2-leaflet';

import windDataJson from '../../data/wind-global.json'
import iconImage from '../../assets/pin.png'

import 'leaflet-velocity/dist/leaflet-velocity.css';
import 'leaflet-velocity/dist/leaflet-velocity';

import moment from 'moment'
import main_function from '../../mixins/main_funxtion'

export default {
    name:'Mapview',
    mixins: [main_function],
    components : {
        LMap,
        LTileLayer,
        LMarker,
        LControlLayers,
        LIcon,
        LControl,
    },
    props : {
        stationdatas : {
            type: Array
        }
    },
    data(){
        return {
            mapshowSelected:'AQI',
            mapshowOptions:[
                { value: 'AQI', text: 'AQI' },
                { value: 'PM25', text: 'PM2.5' },
                { value: 'PM10', text: 'PM10' },
                { value: 'CO', text: 'CO' },
                { value: 'O3', text: 'O3' },
                { value: 'NO2', text: 'NO2' },
                { value: 'SO2', text: 'SO2' }
            ],
            // MAP //
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 4.5,
            currentZoom: 4.5,
            center: [13.4612352,100.3307459],
            user_center: null,
            bounds: null,
            lmap:null,
            windDatas:[],
            tileProviders: [
                {
                name: 'OpenStreetMap',
                visible: true,
                attribution:
                    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                name: 'Grey Canvas',
                visible: false,
                url: "http://{s}.sm.mapstack.stamen.com/(toner-lite,$fff[difference],$fff[@23],$fff[hsl-saturation@20])/{z}/{x}/{y}.png",
                attribution:
                    'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                },
                {
                name: 'World Map',
                visible: false,
                url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                attribution:
                    '',
                },
                {
                    name:'cyclosm',
                    visible: false,
                    url:'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
                }
            ],
            icon: {
                Url: '',
                Size: [56, 68],
                Anchor: [28, 68]
            },
            type_text : ["คุณภาพดีมาก", "คุณภาพดี", "ปานกลาง", "เริ่มมีผลกระทบต่อสุขภาพ", "มีผลกระทบต่อสุขภาพ", "-"],
            type_text_e : ["Very Good", "Good", "Moderate", "Start to affect", "Affect health", "-"],
            params : ["PM25", "PM10", "O3", "NO2", "CO", "SO2"],
            params_sub: {
                PM25:"Avg 24Hr",
                PM10:"Avg 24Hr",
                O3:"Avg 8Hr",
                NO2:"",
                CO:"Avg 8Hr",
                SO2:""
            },
            titleParam : {
                PM25:"<label>PM<sub>2.5</sub></label>",
                PM10:"<label>PM<sub>10</sub></label>",
                O3:"<label>O<sub>3</sub></label>",
                NO2:"<label>NO<sub>2</sub></label>",
                CO:"<label>CO</label>",
                SO2:"<label>SO<sub>2</sub></label>"
            },
            unit : {
                PM25:"<label>ug/m<sup>3</sup></label>",
                PM10:"<label>ug/m<sup>3</sup></label>",
                O3:"<label>ppb</label>", 
                NO2:"<label>ppb</label>", 
                CO:"<label>ppm</label>", 
                SO2:"<label>ppb</label>"
            },
            popupOpen:false,
            popupDisplay:'none',
            popupData:null,

            window_width: window.innerWidth
        }
    },
    methods : {
        checkParamsDraw(_stations){
            // console.log("station",_stations)
            var rParam = ["","","","","",""]
            var count = 0
            for(var a=0;a<this.params.length;a++){
                // console.log(this.params[a])
                // if(_stations.AQILast[this.params[a]].aqi != '-1' && _stations.AQILast[this.params[a]].aqi != '-999'){
               if(parseFloat(_stations.AQILast[this.params[a]].aqi) > 0){
                    rParam[count] = this.params[a]
                    count++
               }
            }
            return rParam
        },
        gotoDetail(sid){
            this.$router.push({ name: 'StationDetail', params: { sid: sid } })
        },
        convertDate(date){
            if(this.$i18n.locale === 'th')
                moment.locale('th')
            else
                moment.locale('en')
            return moment(date).format('DD MMM YYYY HH:mm')
        },
        markerClick (data) {
            this.popupDisplay = ''
            this.popupOpen = true
            this.popupData = data
        },
        closePopup () {
            this.popupOpen = false
            this.popupDisplay = 'none'
            // this.popupData = null
        },
        onReady (mapObject) {
            mapObject.locate();
        },
        onLocationFound(location){
            // console.log("Location",location)
            this.user_center = [location.latlng['lat'],location.latlng['lng']]
            this.zoom = 11
            this.zoomUpdate(11)
            this.$nextTick(() => {
                this.lmap.panTo([location.latlng['lat'],location.latlng['lng']])
            })
        },
        clickGetLocation(){
            if(this.user_center == null){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: 'Get Location Error',
                    icon: 'error'
                })
            }else{
                this.zoom = 11
                this.zoomUpdate(11)
                this.$nextTick(() => {
                    this.lmap.panTo([this.user_center[0],this.user_center[1]])
                })
            }
        },
        getwindDatas(){
            
        },
        addSourcesOverlay(){
            // const layersControl = this.$refs.layersControl.mapObject;
            // console.log('layersControl',layersControl);
            // var velocityLayer = L.velocityLayer({
            //     displayValues: true,
            //     displayOptions: {
            //     velocityType: "Global Wind",
            //     position: "bottomleft",
            //     emptyString: "No wind data"
            //     },
            //     data: this.windDatas,
            //     maxVelocity: 15
            // });

            // velocityLayer.addTo(this.lmap) // show alway
            // layersControl.addOverlay(velocityLayer, "Wind - Great Barrier Reef");
        },
        zoomUpdate(zoom){
            this.currentZoom = zoom
        },
        aqlVal(val,param){
            var AQIval = '-'
            AQIval = val.aqi === "-1" ? "-" : val.aqi === "-999" ? "-" : param === "AQI" ? val.aqi : val.value
            return AQIval
        },
        onResize() {
            this.window_width = window.innerWidth;
        },
    },
    created () {
        this.windDatas = windDataJson
        this.icon.Url = iconImage
        window.addEventListener("resize", this.onResize)
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    mounted() {
        this.lmap = this.$refs['map'].mapObject;
        this.$nextTick(() => {
            this.addSourcesOverlay();
        });
    },
    computed: {
        dynamicSize () {
            // var factor = Math.pow(this.currentZoom / 11.5, 1);
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            if(factor > 0.6) factor = 0.6
            const newX = Math.round(this.icon.Size[0] * factor);
            const newY = Math.round(this.icon.Size[1] * factor);
            return [newX, newY];
            // return [this.icon.Size[0], this.icon.Size[1]];
        },
        dynamicAnchor () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            if(factor > 0.6) factor = 0.6
            const newX = Math.round(this.icon.Size[0] * factor);
            const newY = Math.round(this.icon.Size[1] * factor);
            
            return [newX / 2, newY * 0.8];
            // return [this.icon.Size[0] / 2, this.icon.Size[1] * 0.8];
        },
        dynamicPopupAnchor () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            if(factor > 0.6) factor = 0.6
            const newY = Math.round(this.icon.Size[1] * factor);
            return [0 , -(newY * 0.6)];
            // return [0, -(this.icon.Size[1]* 0.6)];
        },
        dynamicFont () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            if(factor > 0.6) factor = 0.6
            const newSize = Math.round(28 * factor);
            return 'font-size:' + newSize + 'px'
        },
        stations_check () {
            var haveS = this.stationdatas.length
            // console.log('have s',haveS)
            var return_datas = []
            for(var a=0;a<haveS;a++){
                if(this.stationdatas[a].AQILast[this.mapshowSelected].color_id > 0)
                    return_datas.push(this.stationdatas[a])
            }
            return return_datas
        }
    }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width : 550px) {
    .map_layout{
        height: 600px;
    }
    .card_popup{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        transition: 400ms;
        transition-timing-function: ease-in-out;
        transform: translateY(600px);
    }
    .open_popup{
        transform: translateY(0px);
    }
}
@media only screen and (max-width : 550px) {
    .map_layout{
        height: 300px;
    }
    .card_popup{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        transition: 400ms;
        transition-timing-function: ease-in-out;
        transform: translateY(300px);
    }
    .open_popup{
        // transform: translateY(205px);
        transform: translateY(20px);
    }
}
// @media only screen and (max-width : 550px) {
//     .map_layout{
//         height: 300px;
//     }
//     .card_popup{
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         z-index: 9999;
//         width: 100%;
//         transition: 400ms;
//         transition-timing-function: ease-in-out;
//         transform: translateY(600px);
//     }
//     .open_popup{
//         // transform: translateY(205px);
//         transform: translateY(305px);
//     }
// }
// @media only screen and (max-width : 485px) {
//     .open_popup{
//         transform: translateY(190px);
//     }
// }
// @media only screen and (max-width : 415px) {
//     .open_popup{
//         transform: translateY(200px);
//     }
// }
// @media only screen and (max-width : 276px) {
//     .open_popup{
//         transform: translateY(340px);
//     }
// }
.map_layout{
    // height: 600px;
    margin-top:20px;
    border-style: solid;
    border-color: rgb(80, 79, 79);
    border-width:5px;
    border-radius: 10px;
    box-shadow: 2px 5px 10px rgba(68, 68, 68, 0.6);
    transition: 400ms;
    transition-timing-function: ease-in-out;
}
.location{
    background: white;
    color: black;
}
.location:hover{
    background: whitesmoke;
    color: black;
}
.card_popup .close_bt{
    position:absolute;
    top:0;
    right:0;
    margin: 5px;
    // padding: 10px;
    // background: red;
    width: 30px;
    height: 30px;
    text-align: center;
}
.card_popup .close_bt:hover{
    cursor: pointer;
    // background: red;
    // font-size: 20px;
}
.card_popup .close_bt:hover .fas{
    // cursor: pointer;
    // font-size: 20px;
    color: red;
}
.card_popup .detail{
    text-align: left;
}

.aqiFace {
  position: relative;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aqiFace-Text {
  margin: auto;
  position: absolute;
  top: 45%;
  line-height: 16px;
  text-align: center;
}
.aqiFace-Text Text{
  margin-top: 0;
}
.status_text{
    position: absolute;
    bottom: -30px;
}
</style>